<template>
  <div class="page">
    <img src="./img/qdj-bg.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "strengthenPartyBuilding",
};
</script>

<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  img {
    width: 100%;
  }
}
</style>